var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "mt-3 mb-3 text-center" },
            [
              _c(
                "b-alert",
                {
                  staticClass: "col-md-12",
                  attrs: {
                    show: _vm.datafileStatusChangeSuccess,
                    variant: "success",
                    dismissible: "",
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.dismissCountDown = 0
                    },
                    "dismiss-count-down": _vm.countDownChanged,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "datafiles.changeDatafileStatusModal.messages.success"
                      )
                    )
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "col-md-12",
                  attrs: {
                    show: _vm.datafileStatusChangeFailed,
                    variant: "danger",
                    dismissible: "",
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.dismissCountDown = 0
                    },
                    "dismiss-count-down": _vm.countDownChanged,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "datafiles.changeDatafileStatusModal.messages.error"
                      )
                    )
                  ),
                ]
              ),
              this.datafileObject.isLiveCleaning ===
              this.liveCleaningStatus.running
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-md-12 mb-2" }, [
                      _c("img", {
                        staticClass: "img-fluid mb-3 mt-2",
                        staticStyle: { width: "70px" },
                        attrs: {
                          src: require("@/assets/images/stop-button.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-4" }, [
                      _c("h5", [
                        _vm.datafile.name
                          ? _c("strong", [_vm._v(_vm._s(_vm.datafile.name))])
                          : _vm._e(),
                        _vm.datafile.title
                          ? _c("strong", [_vm._v(_vm._s(_vm.datafile.title))])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("h5", { staticClass: "font-weight-800" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafiles.changeDatafileStatusModal.stop.title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "datafiles.changeDatafileStatusModal.stop.actionText"
                            )
                          )
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafiles.changeDatafileStatusModal.stop.description1"
                              )
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafiles.changeDatafileStatusModal.stop.description2"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mt-3" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "danger" },
                            on: { click: _vm.clickChangeDatafileStatus },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafiles.changeDatafileStatusModal.buttons.stop"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: { variant: "light" },
                            on: { click: _vm.clickCloseButton },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafiles.changeDatafileStatusModal.buttons.cancel"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              this.datafileObject.isLiveCleaning ===
              this.liveCleaningStatus.stop
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-md-12 mb-2" }, [
                      _c("img", {
                        staticClass: "img-fluid mb-3 mt-2",
                        staticStyle: { width: "70px" },
                        attrs: {
                          src: require("@/assets/images/play-button.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "col-md-12 mb-4" }, [
                      _c("h5", [
                        _c("strong", [_vm._v(_vm._s(_vm.datafile.name))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("h5", { staticClass: "font-weight-800" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafiles.changeDatafileStatusModal.reopen.title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafiles.changeDatafileStatusModal.reopen.actionText"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "datafiles.changeDatafileStatusModal.reopen.description1"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-12 mt-3" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "success" },
                            on: { click: _vm.clickChangeDatafileStatus },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafiles.changeDatafileStatusModal.buttons.reopen"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-2",
                            attrs: { variant: "light" },
                            on: { click: _vm.clickCloseButton },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafiles.changeDatafileStatusModal.buttons.cancel"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5 pb-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }