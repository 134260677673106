<script>
import DatafileService from "@/api/services/datafile.service";
import LiveCleaningStatus from "@/shared/enums/liveCleaningStatus";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileObject: Object
  },
  data() {
    return {
      datafile: null,
      datafileStatusChangeSuccess: 0,
      datafileStatusChangeFailed: 0,
      isLoading: false,
      dismissSecs: 3,
      dismissCountDown: null,
      liveCleaningStatus: LiveCleaningStatus
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    this.datafile = this.datafileObject;
  },
  methods: {
    async changeDatafileStutsToStart() {
      try {
        const response = await DatafileService.reOpenLiveDatafile(
          this.datafile.id
        );

        if (response) {
          this.isLoading = false;
          this.datafileStatusChangeSuccess = this.dismissSecs;
          this.$emit("reload");
        }
      } catch (error) {
        console.log(error.message);
        this.datafileStatusChangeFailed = this.dismissSecs;
        this.isLoading = false;
      }
    },

    async changeDatafileStutsToStop() {
      try {
        const response = await DatafileService.stopLiveDatafile(
          this.datafile.id
        );

        if (response) {
          this.isLoading = false;
          this.datafileStatusChangeSuccess = this.dismissSecs;
          this.$emit("reload");
        }
      } catch (error) {
        console.log(error.message);
        this.datafileStatusChangeFailed = this.dismissSecs;
        this.isLoading = false;
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    clickCloseButton() {
      this.$emit("close");
    },

    async clickChangeDatafileStatus() {
      this.isLoading = true;
      if (
        this.datafileObject.isLiveCleaning === this.liveCleaningStatus.running
      ) {
        await this.changeDatafileStutsToStop();
      }
      if (this.datafileObject.isLiveCleaning === this.liveCleaningStatus.stop) {
        await this.changeDatafileStutsToStart();
      }
    }
  }
};
</script>

<template>
  <!-- Delete Dataset Model -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="mt-3 mb-3 text-center" v-if="!isLoading">
      <b-alert
        :show="datafileStatusChangeSuccess"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="col-md-12"
        variant="success"
        dismissible
        >{{
          $t("datafiles.changeDatafileStatusModal.messages.success")
        }}</b-alert
      >
      <b-alert
        :show="datafileStatusChangeFailed"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="col-md-12"
        variant="danger"
        dismissible
        >{{ $t("datafiles.changeDatafileStatusModal.messages.error") }}</b-alert
      >

      <!-- Stop Datafile -->
      <div
        class="row justify-content-center"
        v-if="
          this.datafileObject.isLiveCleaning === this.liveCleaningStatus.running
        "
      >
        <!-- Image -->
        <div class="col-md-12 mb-2">
          <img
            src="@/assets/images/stop-button.png"
            alt
            class="img-fluid mb-3 mt-2"
            style="width: 70px"
          />
        </div>

        <!-- Datafile Title  -->
        <div class="col-md-12 mb-4">
          <h5>
            <strong v-if="datafile.name">{{ datafile.name }}</strong>
            <strong v-if="datafile.title">{{ datafile.title }}</strong>
          </h5>
        </div>

        <!-- Text -->
        <div class="col-md-12">
          <h5 class="font-weight-800">
            {{ $t("datafiles.changeDatafileStatusModal.stop.title") }}
          </h5>
          <p>{{ $t("datafiles.changeDatafileStatusModal.stop.actionText") }}</p>
          <p>
            {{ $t("datafiles.changeDatafileStatusModal.stop.description1") }}
            <br />
            {{ $t("datafiles.changeDatafileStatusModal.stop.description2") }}
          </p>
        </div>

        <!-- Buttons -->
        <div class="col-md-12 mt-3">
          <b-button variant="danger" @click="clickChangeDatafileStatus">
            {{ $t("datafiles.changeDatafileStatusModal.buttons.stop") }}
          </b-button>
          <b-button variant="light" @click="clickCloseButton" class="ml-2">
            {{ $t("datafiles.changeDatafileStatusModal.buttons.cancel") }}
          </b-button>
        </div>
      </div>

      <!-- Re Open  Datafile -->
      <div
        class="row justify-content-center"
        v-if="
          this.datafileObject.isLiveCleaning === this.liveCleaningStatus.stop
        "
      >
        <!-- Image -->
        <div class="col-md-12 mb-2">
          <img
            src="@/assets/images/play-button.png"
            alt
            class="img-fluid mb-3 mt-2"
            style="width: 70px"
          />
        </div>

        <!-- Datafile Title  -->
        <div class="col-md-12 mb-4">
          <h5>
            <strong>{{ datafile.name }}</strong>
          </h5>
        </div>

        <!-- Text -->
        <div class="col-md-12">
          <h5 class="font-weight-800">
            {{ $t("datafiles.changeDatafileStatusModal.reopen.title") }}
          </h5>
          <p>
            {{ $t("datafiles.changeDatafileStatusModal.reopen.actionText") }}
          </p>
          <p>
            {{ $t("datafiles.changeDatafileStatusModal.reopen.description1") }}
          </p>
        </div>

        <!-- Buttons -->
        <div class="col-md-12 mt-3">
          <b-button variant="success" @click="clickChangeDatafileStatus">
            {{ $t("datafiles.changeDatafileStatusModal.buttons.reopen") }}
          </b-button>
          <b-button variant="light" @click="clickCloseButton" class="ml-2">
            {{ $t("datafiles.changeDatafileStatusModal.buttons.cancel") }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
